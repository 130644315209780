import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
    Typography,
    TableCell,
    Button,
    Switch,
    Stack,
    Paper,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Chip,
    IconButton,
    Box
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { ViewState } from '@devexpress/dx-react-scheduler'
import { Scheduler, MonthView, Appointments, AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { PATHS, RECURRING, CONFIRMED, PALO_BLUE } from '../../constants'
import SubmitSvg from './SubmitSvg'
import { AddOutlined, ContentCopy, ContentPaste } from '@mui/icons-material'
import moment from 'moment'
import { InputTime, parseTimeBlock, timezoneOffsets } from '../../Utilities/shiftUtils'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import SelectedStore from '../SelectedStore'
import { isMobile } from '../../Utilities/isMobile'
import SelectedEmployee from '../SelectedEmployee'
import SubmitShift from './SubmitShift'
import ChatScheduler from '../openai-stuff/partTimerScheduler'

const Calendar = ({ history, setSnackbar }) => {
    const { calendarYearMonth, employmentId } = useParams()
    const [year, month] = calendarYearMonth.split('-')
    const [shifts, setShifts] = useState([])
    const [recurringShifts, setRecurringShifts] = useState([])
    const [recurringDialog, setRecurringDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [locked, setLocked] = useState(false)
    const [showTooltip, setShowTooltip] = useState(true)
    const [copiedDate, setCopiedDate] = useState(null)
    const { isManagerFor } = useContext(EmploymentsContext)
    const [employees, setEmployees] = useState([])
    const [submitFor, setSubmitFor] = useState()
    const [submitShiftDialog, setSubmitShiftDialog] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [store, setStore] = useState({})
    const [showChat, setShowChat] = useState(false)
    const [blockers, setBlockers] = useState([])

    const blockedDays = useMemo(() => {
        const blocked_shifts_days = shifts
            .filter(s => s.status === 'blocked')
            .map(s => moment(s.startDate).format('YYYY-MM-DD'))
        const shift_blocker_days = blockers.map(s => moment(s.startDate).format('YYYY-MM-DD'))
        return blocked_shifts_days.concat(shift_blocker_days)
    }, [shifts])

    const getAllShiftsFor = submitFor => {
        if (store.id) {
            AxiosWithAuth.get('/shifts', { params: { employment_id: submitFor } }).then(res => {
                let shifts = res.data.shifts
                const blockers = res.data.shift_blockers

                shifts.forEach(e => {
                    const [startDate, endDate] = parseTimeBlock(e.time_block, timezoneOffsets[store.time_zone])
                    e.startDate = startDate
                    e.endDate = endDate
                    e.title = getShiftTitle(startDate, endDate)
                })

                blockers.forEach(e => {
                    const [startDate, endDate] = parseTimeBlock(e.time_block, timezoneOffsets[store.time_zone])
                    e.startDate = startDate
                    e.endDate = endDate
                    e.title = getShiftTitle(startDate, endDate)
                    e.status = 'blocked'
                })
                setBlockers(blockers)
                setShifts(shifts)
            })
        }
    }

    const nonSubmittedShifts = useMemo(() => {
        let val = false
        shifts.forEach(shift => {
            if (shift.startDate.getMonth() + 1 == parseInt(month) && shift.status !== 'submitted') {
                val = true
            }
        })
        return val
    }, [shifts])

    const viewRecurring = useMemo(() => {
        let val = false
        shifts.forEach(shift => {
            if (shift.startDate.getMonth() + 1 == parseInt(month) && shift.is_recurring === true) {
                val = true
            }
        })
        return val
    }, [shifts])

    useEffect(() => {
        if (localStorage.getItem('rsUpdated') && viewRecurring) {
            handleApplyRecurring('update')
            localStorage.removeItem('rsUpdated')
        }
    }, [recurringShifts, viewRecurring])
    const copy = date => {
        copiedDate ? setCopiedDate(false) : setCopiedDate(date)
    }

    const paste = async date => {
        const newShifts = [...shifts]

        const copiedShifts = newShifts.filter(
            shift =>
                shift.startDate.getDate() === copiedDate.getDate() &&
                shift.startDate.getMonth() === copiedDate.getMonth() &&
                shift.status === 'available'
        )

        const destinationShifts = newShifts.filter(
            shift => shift.startDate.getDate() === date.getDate() && shift.startDate.getMonth() === date.getMonth() && shift.startDate.getFullYear() === date.getFullYear()
        )

        const copiedShiftsWithoutDupes = copiedShifts.filter(copiedShift => {
            // removes shifts with the same time in the day that is pasted to
            return !destinationShifts.find(destinationShift => {
                return (
                    destinationShift.startDate.getHours() === copiedShift.startDate.getHours() &&
                    destinationShift.startDate.getMinutes() === copiedShift.startDate.getMinutes()
                )
            })
        })

        copiedShiftsWithoutDupes.forEach(shift => {
            const newShift = { ...shift }
            newShift.startDate = new Date(date)
            newShift.startDate.setHours(shift.startDate.getHours())
            newShift.startDate.setMinutes(shift.startDate.getMinutes())
            const duration = shift.endDate - shift.startDate
            newShift.endDate = new Date(newShift.startDate.getTime() + duration)
            newShift.is_recurring = false
            AxiosWithAuth.post('/shifts', {
                employment_id: submitFor,
                start_time: newShift.startDate,
                end_time: newShift.endDate,
                status: 'available',
                submitted_by: employmentId
            })
                .then(res => {
                    newShift.id = res.data.id
                    newShifts.push(newShift)
                    setShifts(newShifts)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.base) {
                        setSnackbar({ message: err.response.data.base[0], severity: 'error' })
                    }
                })
        })
    }

    const CustomCell = ({ startDate, otherMonth, today }) => {
        const isBlocked = blockedDays.includes(moment(startDate).format('YYYY-MM-DD'))
        const isCopied = copiedDate && copiedDate.getDate() === startDate.getDate()
        return (
            <TableCell
                data-testid={moment(startDate).format('YYYY-MM-DD')}
                className="custom-cell"
                style={{
                    height: '120px',
                    borderLeft: '1px solid rgb(224 224 224)',
                    textAlign: isMobile() ? 'left' : 'center',
                    padding: isMobile() ? '5px' : '',
                    verticalAlign: 'top',
                    position: 'relative'
                }}
                onClick={() => {
                    if (!locked && startDate.getMonth() + 1 === parseInt(month)) {
                        setSubmitShiftDialog(true)
                        setSelectedDate(startDate)
                    }
                }}
            >
                {!locked && !otherMonth && isCopied ? (
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: isMobile() ? '0px' : '5px',
                            right: isMobile() ? '0px' : '10px'
                        }}
                        onClick={e => {
                            e.stopPropagation()
                            copy(startDate)
                        }}
                    >
                        <ContentCopy className="copy-button" fontSize="small" sx={{ color: PALO_BLUE }} />
                    </IconButton>
                ) : !locked && !otherMonth && copiedDate ? (
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: isMobile() ? '0px' : '5px',
                            right: isMobile() ? '0px' : '10px'
                        }}
                        onClick={e => {
                            e.stopPropagation()
                            paste(startDate)
                        }}
                    >
                        <ContentPaste fontSize="small" />
                    </IconButton>
                ) : (
                    shifts.find(
                        shift =>
                            shift.startDate.getDate() === startDate.getDate() &&
                            shift.startDate.getMonth() === startDate.getMonth()
                    ) &&
                    !locked &&
                    !otherMonth &&
                    !isBlocked && (
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: isMobile() ? '0px' : '5px',
                                right: isMobile() ? '0px' : '10px'
                            }}
                            onClick={e => {
                                e.stopPropagation()
                                copy(startDate)
                            }}
                        >
                            <ContentCopy className="copy-button" fontSize="small" />
                        </IconButton>
                    )
                )}
                {today ? (
                    <Chip color="primary" sx={{ fontSize: '1rem' }} label={startDate.getDate()}></Chip>
                ) : (
                    <Typography color={otherMonth ? 'rgba(183, 183, 183, 1)' : 'black'} className="custom-cell-text">
                        {startDate.getDate()}
                    </Typography>
                )}
            </TableCell>
        )
    }

    const CustomHeader = ({ startDate }) => {
        return (
            <TableCell>
                <Typography variant="title" sx={{ textAlign: 'center' }}>
                    {Intl.DateTimeFormat('ja', { weekday: 'short' }).format(startDate)}
                </Typography>
            </TableCell>
        )
    }

    const handleShiftDelete = appointmentData => {
        AxiosWithAuth.delete(`/shifts/${appointmentData.id}`)
            .then(() => {
                setShowTooltip(false)
                setShifts(shifts.filter(shift => shift.id !== appointmentData.id))
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleShiftBlockerDelete = appointmentData => {
        AxiosWithAuth.delete(`/shift_blockers/${appointmentData.id}`)
            .then(() => {
                setShowTooltip(false)
                setBlockers(blockers.filter(shift => shift.id !== appointmentData.id))
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleShiftEdit = async shift => {
        const [startHour, startMinute] = shift.startTime.split(':')
        const [endHour, endMinute] = shift.endTime.split(':')
        const year = shift.startDate.getFullYear()
        const month = shift.startDate.getMonth()
        const day = shift.startDate.getDate()
        const startTimeDate = new Date(year, month, day, startHour, startMinute)
        const startTokyoHourOffset = startTimeDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]
        const endTimeDate = new Date(year, month, day, endHour, endMinute)
        const endTokyoHourOffset = endTimeDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]

        if (startTimeDate.getHours() > endTimeDate.getHours()) {
            // night shift
            endTimeDate.setDate(endTimeDate.getDate() + 1)
        }

        startTimeDate.setHours(startTimeDate.getHours() - startTokyoHourOffset)
        endTimeDate.setHours(endTimeDate.getHours() - endTokyoHourOffset)

        await AxiosWithAuth.put('/shifts/' + shift.id, {
            employment_id: submitFor,
            start_time: startTimeDate,
            end_time: endTimeDate,
            status: shift.status,
            submitted_by: employmentId
        }).catch(err => {
            if (err.response.status === 422 && err.response.data?.time_block) {
                setSnackbar({ message: '他店舗でのシフトと重複しています', severity: 'error' })
            }
            if (err.response.data.base) {
                setSnackbar({ message: err.response.data.base[0], severity: 'error' })
            }
        })

        setShowTooltip(false)
        getAllShiftsFor(submitFor)
    }

    const handleApplyRecurring = async (action = '') => {
        if (viewRecurring || action === 'update') {
            // delete all recurring shifts
            const shiftsToDelete = []
            shifts.forEach(shift => {
                if (shift.startDate.getMonth() + 1 == parseInt(month) && shift.is_recurring === true) {
                    shiftsToDelete.push(shift.id)
                }
            })
            // iterate over current month shifts and delete recurring shifts`
            await AxiosWithAuth.delete('/multishifts', { data: { shift: { ids: shiftsToDelete } } })
                .then(() => {
                    getAllShiftsFor(submitFor)
                    setRecurringDialog(false)
                })
                .catch(err => console.error(err))
        }
        if (!viewRecurring || action === 'update') {
            const shiftsToCreate = recurringShifts.map(({ startDate, endDate }) => {
                const freshStartDate = new Date(startDate)
                const freshEndDate = new Date(endDate)
                const startTokyoHourOffset = freshStartDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]
                const endTokyoHourOffset = freshEndDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]

                freshStartDate.setHours(freshStartDate.getHours() - startTokyoHourOffset)
                freshEndDate.setHours(freshEndDate.getHours() - endTokyoHourOffset)

                return {
                    employment_id: submitFor,
                    start_time: freshStartDate,
                    end_time: freshEndDate,
                    status: 'available',
                    is_recurring: true,
                    submitted_by: employmentId
                }
            })
            AxiosWithAuth.post('/multishifts', { shifts: shiftsToCreate })
                .then(() => {
                    getAllShiftsFor(submitFor)
                    setRecurringDialog(false)
                })
                .catch(error => console.error(error))
        }
    }

    const headerButtonStyle = {
        margin: '5px 5px 5px 5px'
    }
    moment.updateLocale('en', {
        weekdaysShort: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
    })

    const TooltipHeader = ({ appointmentData }) => {
        const startDate = appointmentData.startDate
        return (
            <Stack style={{ margin: '1rem' }}>
                <Typography variant="h6" color="text" align="center" margin={2}>
                    {moment(startDate, 'YYYY-MM-DD').format('YYYY年M月D日 ddd')}
                </Typography>
                {appointmentData.status !== 'confirmed' && (
                    <Typography variant="h6" color="text" align="center">
                        シフトの編集
                    </Typography>
                )}
                {appointmentData.status === 'blocked' && (
                    <Typography variant="h7" color="text" align="center">
                        他店舗でシフトが存在するため、このシフトを変更・保存することはできません
                    </Typography>
                )}
            </Stack>
        )
    }

    const FrederickAppointmentContent = ({ appointmentData }) => {
        const [startError, setStartError] = useState(false)
        const [endError, setEndError] = useState(false)
        const [shift, setShift] = useState({
            ...appointmentData,
            startTime: moment(appointmentData.startDate).format('HH:mm'),
            endTime: moment(appointmentData.endDate).format('HH:mm')
        })

        const handleKeyPress = ev => {
            if (ev.key === 'Enter') {
                setShift({ ...shift, startTime: ev.target.value })
                if (!startError && !endError) {
                    handleShiftEdit(shift)
                }
            }
        }

        useEffect(() => {
            if (shift.startTime.split(':')[1] % 15 > 0) {
                setStartError(true)
            } else {
                setStartError(false)
            }
            if (shift.endTime.split(':')[1] % 15 > 0) {
                setEndError(true)
            } else {
                setEndError(false)
            }
        }, [shift])
        return (
            <div>
                <Stack margin={2} spacing={1.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputTime
                        startValue={shift.startTime}
                        endValue={shift.endTime}
                        disabled={locked || shift.status === 'blocked' || shift.status === 'confirmed'}
                        startOnChange={e => setShift({ ...shift, startTime: e.target.value })}
                        endOnChange={e => setShift({ ...shift, endTime: e.target.value })}
                        sx={{ marginTop: '.5rem' }}
                        onKeyPress={handleKeyPress}
                        startError={startError}
                        endError={endError}
                    />
                </Stack>
                <Stack direction="row" justifyContent="center" margin={2} spacing={4}>
                    <ButtonWithConfirmPrompt
                        component={
                            <Button
                                variant="outlined"
                                style={headerButtonStyle}
                                startIcon={<DeleteIcon />}
                                disabled={locked || shift.status === 'confirmed'}
                                color="error"
                            >
                                削除
                            </Button>
                        }
                        title="シフトの削除"
                        content="削除してもよろしいですか？"
                        confirmText="削除する"
                        handleConfirm={() => {
                            shift.status === 'blocked' ? handleShiftBlockerDelete(shift) : handleShiftDelete(shift)
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={headerButtonStyle}
                        disabled={
                            locked ||
                            startError ||
                            endError ||
                            shift.status === 'blocked' ||
                            shift.status === 'confirmed'
                        }
                        onClick={() => {
                            handleShiftEdit(shift)
                        }}
                    >
                        保存
                    </Button>
                </Stack>
            </div>
        )
    }

    const customAppointment = ({ style, children, data, ...rest }) => {
        if (data.status == 'confirmed' && !data.is_released) return null
        return (
            <Appointments.Appointment
                data={data}
                style={{
                    ...style,
                    backgroundColor:
                        data?.status === 'blocked'
                            ? '#ADAEAD'
                            : data?.is_recurring === true && data?.status === 'available'
                            ? RECURRING
                            : data.status === 'confirmed'
                            ? CONFIRMED
                            : data.status === 'available'
                            ? '#64b5f6' // this needs to be dots
                            : data.submitted_by && data.submitted_by !== data.employment_id
                            ? '#90EE90'
                            : '#b6bce2'
                }}
                {...rest}
            >
                {children}
            </Appointments.Appointment>
        )
    }

    const customAppointmentContent = ({ ...restProps }) => {
        // if (restProps.data.status == 'confirmed' && !restProps.data.is_released) return null
        return (
            <Appointments.AppointmentContent {...restProps}>
                <div className={restProps.container}>
                    <div style={{ whiteSpace: 'normal' }}>{restProps.data?.title}</div>
                </div>
            </Appointments.AppointmentContent>
        )
    }

    const getShiftTitle = (start, end) => {
        const startHours = start.getHours()
        const startMinutes = ('00' + start.getMinutes()).slice(-2)
        const endHours = end.getHours()
        const endMinutes = ('00' + end.getMinutes()).slice(-2)
        return `${startHours}:${startMinutes} ${endHours}:${endMinutes}`
    }

    const handleFinalize = async () => {
        const ids = shifts
            .filter(e => {
                return e.status === 'available' && e.startDate.getMonth() + 1 == parseInt(month)
            })
            .map(e => {
                return e.id
            })
        const shiftRes = await AxiosWithAuth.put('/multishifts', {
            shift: {
                ids: ids,
                status: 'submitted'
            }
        })
        if (shiftRes?.status === 200) {
            localStorage.setItem('viewRecurring', false)
            setSubmitted(true)
        }
    }

    useEffect(() => {
        setShowTooltip(true)
    }, [recurringShifts, shifts])

    useEffect(() => {
        setSubmitFor(localStorage.getItem('submit_for') || employmentId)
    }, [employmentId])

    useEffect(async () => {
        setLocked(false)
        if (!submitFor) return
        getAllShiftsFor(submitFor)
        await AxiosWithAuth.get('/recurring_shifts', {
            params: { employment_id: submitFor }
        }).then(res => {
            setRecurringShifts(parseRecurring(res.data, calendarYearMonth, getShiftTitle))
        })
        AxiosWithAuth.get('/employments/' + submitFor).then(res => {
            if (res.data?.store?.closed_months?.includes(calendarYearMonth)) {
                setLocked(true)
            }
            if (res.data?.submitted && res.data?.submitted?.find(e => e === calendarYearMonth)) {
                setLocked(true)
            }
            setStore(res.data?.store)
        })
        if (
            employees.length > 0 &&
            employees.find(e => e.id === parseInt(submitFor))?.submitted?.find(e => e === calendarYearMonth)
        ) {
            setLocked(true)
        }
    }, [calendarYearMonth, submitFor])

    useEffect(() => {
        if (store.id && submitFor) {
            getAllShiftsFor(submitFor)
        }
    }, [store, submitFor])

    useEffect(() => {
        if (!isManagerFor) return
        if (isManagerFor[employmentId]) {
            AxiosWithAuth.get('/calendars/dailyShifts', {
                params: { employment_id: localStorage.getItem('selected_emp'), date: calendarYearMonth + '-01' }
            }).then(res => {
                setEmployees(res.data.employments)
            })
        }
    }, [isManagerFor])

    if (submitted) {
        return (
            <div
                style={{
                    height: 'calc(100vh - 56px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 1rem'
                }}
            >
                <SubmitSvg />
                <Typography variant="header1" sx={{ fontSize: '20px' }}>
                    希望シフトを提出しました。
                </Typography>
                <Button
                    onClick={() => {
                        localStorage.removeItem('viewRecurring')
                        history.push(PATHS.home)
                    }}
                    variant="contained"
                    color="paloBlue"
                    fullWidth
                    sx={{ mt: '2rem', mb: '2rem', width: isMobile() ? '100%' : '20%' }}
                >
                    ホームに戻る
                </Button>
            </div>
        )
    }

    return (
        <PageWithTitleLayout title="希望シフトを提出する">
            <Box sx={{ mx: isMobile() ? '0%' : '20%' }}>
                <SelectedStore disabled={true} />
                <SelectedEmployee employees={employees} setSubmitFor={setSubmitFor} submitFor={submitFor} />
                <Typography variant="subtitle2" margin="1rem" marginTop=".5rem" marginBottom=".5rem">
                    日にちを選択して時間帯を設定してください。
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="subtitle1" margin="1rem">
                        {year}年{parseInt(month)}月
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Dialog open={recurringDialog}>
                            <DialogTitle>
                                {viewRecurring ? '今月の固定シフトを全て削除します' : '固定シフトを適用します。'}
                            </DialogTitle>
                            <DialogContent>よろしいですか？</DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => setRecurringDialog(false)}>
                                    キャンセル
                                </Button>
                                <Button onClick={handleApplyRecurring} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Typography variant="subtitle1">固定シフト</Typography>
                        <Switch
                            checked={viewRecurring}
                            disabled={locked}
                            onChange={() => {
                                setRecurringDialog(true)
                            }}
                            color="paloBlue"
                        />
                    </Stack>
                </div>

                <Paper sx={{ margin: !isMobile() ? '1rem' : '1rem 0' }}>
                    <Scheduler
                        data={shifts
                            .filter(shift => {
                                return locked
                                    ? shift.status === 'submitted' ||
                                          shift.status === 'available' ||
                                          shift.status === 'blocked' ||
                                          shift.status === 'confirmed'
                                    : shift.status === 'available' ||
                                          shift.status === 'confirmed' ||
                                          shift.status === 'blocked'
                            })
                            .concat(blockers)}
                        firstDayOfWeek={1}
                    >
                        <ViewState currentDate={new Date(year, parseInt(month) - 1)} />
                        <MonthView timeTableCellComponent={CustomCell} dayScaleCellComponent={CustomHeader} />
                        <Appointments
                            appointmentComponent={customAppointment}
                            appointmentContentComponent={customAppointmentContent}
                        />
                        {showTooltip && (
                            <AppointmentTooltip
                                headerComponent={TooltipHeader}
                                contentComponent={FrederickAppointmentContent}
                            />
                        )}
                        {submitShiftDialog && (
                            <Dialog open={submitShiftDialog}>
                                <DialogContent>
                                    <SubmitShift
                                        shiftDate={moment(selectedDate).format('YYYY-MM-DD')}
                                        submitFor={submitFor}
                                        setSubmitShiftDialog={setSubmitShiftDialog}
                                        setAllShifts={getAllShiftsFor}
                                        store={store}
                                        setSnackbar={setSnackbar}
                                    />
                                </DialogContent>
                            </Dialog>
                        )}
                    </Scheduler>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {showChat ? (
                            <ChatScheduler
                                getAllShiftsFor={getAllShiftsFor}
                                employmentId={employmentId}
                                year={year}
                                month={month}
                            />
                        ) : (
                            <Button onClick={() => setShowChat(true)}>show chat</Button>
                        )}
                    </div>
                </Paper>
            </Box>
            <Button
                onClick={() => history.push({ pathname: '/recurringshifts/' + submitFor, state: { viewRecurring } })}
                variant="outlined"
                color="paloBlue"
                className="main-button"
                sx={{ mx: isMobile() ? '.5rem' : 'auto', mt: 'auto', mb: '1rem' }}
                startIcon={<AddOutlined />}
            >
                固定シフトの設定
            </Button>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        disabled={!nonSubmittedShifts || locked}
                        variant="contained"
                        color="paloBlue"
                        className="main-button"
                        sx={{ mx: isMobile() ? '.5rem' : 'auto', mb: '2rem' }}
                    >
                        提出する
                    </Button>
                }
                title="希望シフトの提出"
                content="提出したあとは変更できません。提出してよろしいですか？"
                confirmText="提出する"
                handleConfirm={handleFinalize}
            />
        </PageWithTitleLayout>
    )
}

export const parseRecurring = (data, calendarYearMonth, getShiftTitle) => {
    const monthStart = moment(calendarYearMonth, 'YYYY-MM').startOf('month')
    const monthEnd = moment(calendarYearMonth, 'YYYY-MM').endOf('month')
    const parsedRecurring = []
    data.forEach(e => {
        const current = monthStart.clone().day(e.day)
        if (current >= monthStart) {
            current.add(-7, 'day')
        }
        while (current.add(7, 'day') <= monthEnd) {
            const clone = current.clone()
            const start_time = clone.toDate()
            start_time.setHours(...e.start_time.split(':'))
            const end_time = clone.toDate()
            end_time.setHours(...e.end_time.split(':'))
            end_time < start_time && end_time.setDate(end_time.getDate() + 1)
            parsedRecurring.push({
                id: start_time.getTime(),
                startDate: start_time,
                endDate: end_time,
                title: getShiftTitle(start_time, end_time),
                status: 'recurring'
            })
        }
    })
    return parsedRecurring
}

export default Calendar
